<template>
  <vx-card :title="title">
    <vs-tabs :value="active">
      <vs-tab label="Role">
        <Role></Role>
      </vs-tab>
      <vs-tab label="Sales Channel">
        <SalesChannel></SalesChannel>
      </vs-tab>
      <vs-tab label="Users">
        <Users></Users>
      </vs-tab>
      <vs-tab label="Users Structure">
        <UsersStructure></UsersStructure>
      </vs-tab>
      <vs-tab label="Work Group">
        <WorkGroup></WorkGroup>
      </vs-tab>
      <vs-tab label="Work position">
        <WorkTeam></WorkTeam>
      </vs-tab>
    </vs-tabs>
  </vx-card>
</template>
<script>
import Role from "./role/index.vue";
import SalesChannel from "./sales-channel/index.vue";
import Users from "./users/index.vue";
import UsersStructure from "./user-structure/index.vue";
import WorkGroup from "./work-group/index.vue";
import WorkTeam from "./work-position/index.vue";

export default {
  props: {
    active: {
      type: Number,
    },
  },
  components: {
    Role,
    SalesChannel,
    Users,
    UsersStructure,
    WorkGroup,
    WorkTeam,
  },
  data() {
    return {
      title: "User",
    };
  },
};
</script>
